import { FormikProps, FormikValues } from 'formik';
import { ReactNode } from 'react';

import { ColorTheme } from './common';
import { FieldIconType } from '../components/FormField/Icon/Icon';
import { GenFormConfig, GenFormFieldsConfig } from '../components/FormGenerator/FormGenerator';

export type SingleValue<T> = T | null;
export type MultiValue<T> = T[] | null;
export type PropsValue<T> = SingleValue<T> | MultiValue<T>;

export enum FieldTypes {
  NUMBER = 'number',
  TEXT = 'text',
  SELECT = 'select',
  GROUP = 'fieldGroup',
  SWITCH = 'switch',
  TABS = 'tabs',
}

export type TabOption<T> = {
  label: string;
  value: T;
};

export type OptionsType = {
  value: string | null | boolean | number;
  label: string;
  isDisabled?: boolean;
  isSyntheticOption?: boolean;
};

export type Field = {
  label?: string;
  name: string;
  type?: FieldTypes;
  labelClass?: string;
  classContainer?: string;
  colorTheme?: ColorTheme;
  disabled?: boolean;
  isFetching?: boolean;
  options?: OptionsType[];
  defaultValue?: string | null;
  icon?: FieldIconType;
  vertical?: boolean;
  placeholder?: string;
  onChange?: null | FieldOnChangeFn;
  showSwitchText?: boolean;
  defaultChecked?: boolean;
  hideArrow?: boolean;
  noOptionsMessage?: () => string | null;
  onInputChange?: (str: string) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  formikBasedValue?: PropsValue<OptionsType>;
  error?: string;
  childComponent?: JSX.Element;
  validateOnChange?: boolean;
  required?: boolean;
  isMulti?: boolean;
};

export type FieldOnChangeFn = (event: never) => void;

export type BaseFieldProps = Field & {
  formik?: FormikProps<FormikValues>;
  children?: ReactNode;
};

export type FieldConfigHookParams<T extends FormikValues> = {
  formik: FormikProps<T>;
};

export type FieldConfigHook<
  FormValues extends FormikValues,
  AdditionalParams extends Record<string, unknown> = Record<string, unknown>,
  Result extends Field = Field,
> = (params: FieldConfigHookParams<FormValues> & AdditionalParams) => Partial<Result>;

export type UseFormConfigParams<FormValues> = {
  formik: FormikProps<FormValues>;
  isValidating?: boolean;
};

export type UseFormConfig = {
  config: GenFormConfig;
  fieldsConfig: GenFormFieldsConfig;
};

export type FieldsConfig<FormValues extends FormikValues, AdditionalParams extends Record<string, unknown> = Record<string, unknown>> = (
  params: UseFormConfigParams<FormValues> & AdditionalParams,
) => UseFormConfig;
