import { ChakraProvider } from '@chakra-ui/react';
import Form from '@rjsf/chakra-ui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import equals from 'fast-deep-equal';
import React, { FC, useMemo, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { TabOption } from '../../types/field';

import { ClientSettingsSelect } from './components/SelectComponent/ClientSettingsSelect';
import { uiSchema } from './form/config';
import { useClientSettings } from './hooks/useClientSettings';
import { useClientSettingsJsonSchema } from './hooks/useClientSettingsJsonSchema';
import { getSourceIdFromLocalStorage, mapInitialData } from './shared/utils';
import { useNavigator } from '../../common/modules/navigation/useNavigator';
import { fetchClientSettingsUpdate } from '../../common/queries/clientSettingsProxy/clientSettingsProxy.api';
import { FetchClientSettingsUpdateParams } from '../../common/queries/clientSettingsProxy/clientSettingsProxy.types';
import { DialogModal } from '../../components/DialogModal/DialogModal';
import { ErrorWrapper } from '../../components/ErrorWrapper/ErrorWrapper';
import { Page } from '../../components/Page/Page';
import { TabMenu } from '../../components/TabMenu/TabMenu';
import phrases from '../../constants/en_US.json';
import { QueryKey } from '../../constants/queries';
import { APP_NAME } from '../../constants/variables';
import { ClientSettings } from '../../types/clientSettings';
import { CLIENT_SETTINGS_TABS } from '../ClientListPage/shared/constants';
import { ClientType } from '../ClientListPage/shared/types';
import { ClientNEXTInspectSubTab } from '../ClientListPage/shared/types';

export const ClientSettingsPage: FC = (): JSX.Element => {
  const { id: client } = useParams();
  const location = useLocation();
  const from = (location.state as { from?: ClientNEXTInspectSubTab })?.from ?? ClientNEXTInspectSubTab.ACTIVE_MANHEIM;
  const [newTab, setNewTab] = useState(CLIENT_SETTINGS_TABS[0]);
  const [clientType, setClientType] = useState(ClientType.DEALER);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const { navigateToClientsNextInspect } = useNavigator();
  const queryClient = useQueryClient();
  const showError = (msg?: string) => toast(msg ?? `Can't find data`, { type: 'error' });
  const showSuccess = () => toast('The data has been updated', { type: 'success' });

  const { mutate, isLoading } = useMutation({
    mutationFn: (params: FetchClientSettingsUpdateParams) => fetchClientSettingsUpdate(params),
    onError: (e: Error) => showError(e.message),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKey.CLIENT_SETTINGS_PROXY, client as string] });
      !client && void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENT_SETTINGS_ACCOUNTS] });
      showSuccess();
    },
  });

  const { data: jsonSchema, isFetching: jsonSchemaIsFetching, error: jsonSchemaError } = useClientSettingsJsonSchema();

  const { data, isFetching } = useClientSettings({
    client: client ?? 'demoBasic',
    onError: () => {
      navigateToClientsNextInspect(from);
      showError(phrases.clientSettingsNotFound);
    },
  });
  const sourceId = useMemo(getSourceIdFromLocalStorage, []);

  const currentData = data?.items?.find((settings) => settings.clientType === clientType);
  const currentTab = CLIENT_SETTINGS_TABS.find((tab) => tab.label.toLocaleLowerCase() === clientType);

  const handleOnSubmit = (clientSettings: ClientSettings) => {
    mutate({ values: { ...clientSettings, user: { source: APP_NAME, sourceId } } });
  };

  const onTabChangeCallback = (newTab: TabOption<ClientType>) => {
    setClientType(newTab?.value.toLocaleLowerCase() as ClientType);
  };

  const fetchError = jsonSchemaError;
  const jsonSchemaForClientSettings = jsonSchema?.schema ?? ({} as RJSFSchema);

  const initialFormData = mapInitialData(currentData, jsonSchemaForClientSettings);
  const [formData, setFormData] = useState(currentData);

  const isPageLoading = isFetching || isLoading || !formData || jsonSchemaIsFetching;

  useEffect(() => {
    if (jsonSchemaForClientSettings && currentData) {
      setFormData(mapInitialData(currentData, jsonSchemaForClientSettings) as ClientSettings);
    }
  }, [currentData]);

  const handleChange = ({ formData }: { formData: ClientSettings }) => {
    setFormData(formData);
  };

  const onDiscardModalClose = (): void => {
    setIsDiscardModalOpen(false);
  };

  const onTabChange = (newTab: TabOption<ClientType>) => {
    if (newTab !== currentTab) {
      if (equals(formData, initialFormData)) {
        onTabChangeCallback(newTab);
      } else {
        setIsDiscardModalOpen(true);
        setNewTab(newTab);
      }
    }
  };

  return (
    <Page id="client-settings" title="Client settings" isLoading={isPageLoading}>
      <ErrorWrapper error={fetchError}>
        <DialogModal
          isOpen={isDiscardModalOpen}
          onClose={onDiscardModalClose}
          onSubmitButton={() => onTabChangeCallback(newTab)}
          submitButtonText="Continue without saving"
        >
          <div className="items-center prism-heading-2 self-center mb-8">You have unsaved changes. Do you want to leave?</div>
        </DialogModal>
        <div className="items-center pb-4 px-4">
          <div className="w-full">
            <div className="my-2 flex items-center gap-4 mb-3" data-testid="group-buttons">
              <button onClick={() => navigateToClientsNextInspect(from)} data-testid="client-list-btn" className="prism-btn">
                Client list
              </button>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center pl-4 pr-4">
          <ChakraProvider>
            {!isPageLoading && (
              <div data-testid={`client-settings-form-container-${formData?.clientType as string}`} className="flex flex-col w-3/5">
                <TabMenu
                  container="h-10"
                  tabs={CLIENT_SETTINGS_TABS}
                  selectedTab={currentTab || CLIENT_SETTINGS_TABS[0]}
                  onTabChange={onTabChange}
                />
                <div data-testid="edit-client-settings-form" className="border rounded p-3 mb-6 react-select-reset">
                  <Form
                    schema={jsonSchemaForClientSettings}
                    uiSchema={uiSchema}
                    validator={validator}
                    onSubmit={({ formData: clientSettings }) => handleOnSubmit(clientSettings as ClientSettings)}
                    formData={formData}
                    widgets={{
                      SelectWidget: ClientSettingsSelect,
                    }}
                    onChange={handleChange as never}
                    showErrorList="bottom"
                    liveValidate={true}
                  />
                </div>
              </div>
            )}
          </ChakraProvider>
        </div>
      </ErrorWrapper>
    </Page>
  );
};
